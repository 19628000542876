import { DownOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from "@apollo/client";
import { Table } from "ant-table-extensions";
import { Button, Dropdown, Menu, PageHeader, Skeleton, Switch } from "antd";
import "antd/dist/antd.min.css";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useMemo, useState } from "react";
import { FEATURE_FLAGS_QUERY, SAVE_FEATURE_FLAG_GATE } from "./FeatureFlagsQueries";
import NewFeatureFlag from './NewFeatureFlag';

const FeatureFlags = () => {
  const { data, error, loading } = useQuery(FEATURE_FLAGS_QUERY);
  const [updateFlagGate, updateResult] = useMutation(SAVE_FEATURE_FLAG_GATE);
  const [newFeatureModal, setNewFeatureModal] = useState(false)

  const featureFlags = useMemo(() => !error && !loading && data ? data.flagFeatures : [] , [data, error, loading]);
  const accounts = useMemo(() => !error && !loading && data ? data.accounts : [], [data, error, loading]);

  const [flags, setFlags] = useState({});
  // console.log({ data, error, loading });

  // console.log(accounts.reduce((prev, current) => {
  //   const maps = current.flagFeatureGates.map(flag => ({ [flag.flagFeature.featureKey]: flag.value === 'true' }))
  //   console.log({ prev, current, maps });

  //   return { ...prev, [current.id]: current.flagFeatureGates.map(flag => ({ [flag.flagFeature.featureKey]: flag.value === 'true' })) }
  // }, {}));

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setFlags(() => accounts.reduce((prev, current) => ({ ...prev, [current.id]: current.flagFeatureGates.reduce((obj, flag) => ({ ...obj, [flag.flagFeature.featureKey]: flag.value === 'true' }), {}) }), {}));
    }
  }, [accounts]);

  const onChange = (value, event) => {
    debugger;
    const name = event.target.id || event.target.offsetParent.id;
    const vals = name.split(':');
    const gateFlagId = vals[0];
    setFlags(old => ({...old, [vals[1]]: ({...old[vals[1]], [vals[2]]: value}) }));
    updateFlagGate({variables: {id: gateFlagId, value: value.toString()}});
  }
  console.log(flags);
  const columns = useMemo(() => {
    const ret = [
      {
        title: 'Account',
        dataIndex: 'name',
        key: 'name',
        render: text => text,
      }
    ];

    for (const element of featureFlags) {
      ret.push({
        title: element.featureKey,
        render: object => {
          // const gates = object.flagFeatureGates;
          const gate = object.flagFeatureGates.find(gate => gate.flagFeature.featureKey === element.featureKey);
          // console.log(gate);
          return <Switch id={gate.id + ":" + object.id + ":" + element.featureKey} checked={flags && flags[object.id] && flags[object.id][element.featureKey]} onClick={onChange} />;
        }
      })
    }
    return ret;
  }, [featureFlags, flags]);


  const menu = (
    <Menu>
      <Menu.Item key="rule_assingment">
        <div onClick={() => setNewFeatureModal(true)}>
          Novo Feature Flag
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="other_rule" disabled>
        Example（disabled）
      </Menu.Item>
    </Menu>
  );


  return (
    <div>
      <Content style={{ margin: "0 16px", backgroundColor: "white" }}>
        <div
          className="site-page-header-ghost-wrapper"
          style={{ marginTop: "10px" }}
        >
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Feature Flags"
            extra={[
              <Dropdown overlay={menu} key="drop">
                <Button
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Ações <DownOutlined />
                </Button>
              </Dropdown>,
            ]}
          ></PageHeader>
          {loading && (
            <div style={{ padding: "24px" }}>
              <Skeleton active={true} />
            </div>
          )}
          {!loading && <Table
            dataSource={accounts}
            columns={columns}
            size="small"
            pagination={{ pageSize: "50" }}
            rowKey="id"
          />}
        </div>
      </Content>
      <NewFeatureFlag
        isOpen={newFeatureModal}
        setIsOpen={setNewFeatureModal}
        accountIds={accounts.map(account => account.id)}
      />
    </div>
  );
};

FeatureFlags.propTypes = {
}

export default FeatureFlags;
