import { useMutation } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Popconfirm, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { CREATE_NEW_FEATURE_FLAG } from "./FeatureFlagsQueries";

const CreateFeatureForm = ({ form, onFinish, onFailed }) => {
  return (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFailed}
      initialValues={{
        feature_key: "",
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item label="Feature" name="feature_key">
            <Input
              required
              placeholder="Feature"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>

  );
};

CreateFeatureForm.propTypes = {
  form: PropTypes.object,
  onFinish: PropTypes.func,
  onFailed: PropTypes.func,
}


const NewFeatureFlag = ({ isOpen, setIsOpen, accountIds }) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const [createNewFlag,] = useMutation(CREATE_NEW_FEATURE_FLAG);
  const [form] = useForm();
  // const clearStates = () => {
  //   setOrganizationName("");
  // };
  // { accountId: "", value: "" }

  const handleSubmit = (mutationVariables) => {
    setMutationLoading(true);
    createNewFlag({
      variables: mutationVariables,
    }).then(() => {
      console.log('success')
    }).finally(() => {
      setMutationLoading(false);
      setIsOpen(false);
    });
  };

  const formFinished = (formValue) => {
    setIsOpen(false);
    const mutationVariables = {
      featureKey: formValue.feature_key,
      data: accountIds.map(accId => ({accountId: accId, value: "false"})),
    };
    handleSubmit(mutationVariables);
  };

  const formFailed = (...args) => {
    console.log(args);
    setIsOpen(false);
  };
  return (
    <div>
      <Modal
        title="Criar Feature Flag"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={[
          <Button key="back" onClick={() => setIsOpen(false)}>
            Cancelar
          </Button>,
          <Popconfirm key="action"
            title="Você quer criar esta feature flag?"
            onConfirm={() => form.submit()}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type="primary"
              loading={mutationLoading}
            >
              Salvar
            </Button>
          </Popconfirm>,
        ]}
      >
        <CreateFeatureForm
          form={form}
          onFailed={formFailed}
          onFinish={formFinished}
        />
      </Modal>
    </div>
  );
};

NewFeatureFlag.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  accountIds: PropTypes.array,
}
export default NewFeatureFlag;
