import { gql } from "@apollo/client";

export const FEATURE_FLAGS_QUERY = gql`
  query FeatureFlags {
    flagFeatures {
      __typename
      id
      featureKey
      createdAt
    }
    accounts(where: {deleted_at: {_is_null: true}}, order_by: {name: asc}) {
      __typename
      id
      name
      flagFeatureGates {
        __typename
        id
        flagFeature {
          __typename
          id
          featureKey
        }
        value
        createdAt
        updatedAt
      }
    }
  }
`;

export const SAVE_FEATURE_FLAG_GATE = gql`
  mutation UpdateFlagGate($id: uuid!, $value: String!) {
    updateByPkFlagFeatureGates(pk_columns: {id: $id}, _set: {value: $value}) {
      updatedAt
    }
  }
`;

export const CREATE_NEW_FEATURE_FLAG = gql`
  mutation CreateFeatureFlag($featureKey: String!, $data: [flagFeatureGates_insert_input!]!) {
    insertFlagFeaturesOne(object: {featureKey: $featureKey, flagFeatureGates: {data: $data}}) {
      createdAt
      featureKey
      id
    }
  }
`;
