import { useMutation, useQuery } from "@apollo/client";
import * as cnpj from "@fnando/cnpj";
import {
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input, InputNumber, Modal,
  Space, Typography,
  notification
} from "antd";
import "antd/dist/antd.min.css";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from "react";
import { useAuth } from "../../../Auth";
import { OrganizationsSelector } from "../../Organizations/OrganizationsSelector";
import { CREATE_ACCOUNT_WITH_CONTRACT, FEATURE_FLAGS } from "../AccountsQueries";

const { Title } = Typography;

const openNotification = (data) => {
  var contractName = data?.insertAccountsOne.name;
  notification.open({
    message: "Conta Criado com Sucesso!",
    type: "success",
    description: contractName,
    onClick: () => {
      console.log("Notification Clicked!");
    },
  });
};

const NewAccount = ({
  newAccountModalVisible,
  setNewAccountModalVisible
}) => {
  const [mutationLoading, setMutationLoading] = useState(false);
  const { currentUser: user } = useAuth();
  const formRef = useRef();
  const { data, error } = useQuery(FEATURE_FLAGS);
  const [createNewAccount ] = useMutation(CREATE_ACCOUNT_WITH_CONTRACT);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(null);

  const flagFeatures = useMemo(() => data?.flagFeatures ?? [], [data]);

  const resetStates = () => {
    console.log(formRef);
    formRef.current.resetFields();
  };

  const onFinish = (values) => {
    const payload = {
      name: values.name,
      organization_id: values.organization.id,
      organization: values.organization,
      contract: {
        started_at: values.contract__started_at,
        period_in_months: values.contract__period_in_months,
        processing_quota: values.contract__processing_quota,
        seats_quota: values.contract__seats_quota,
      }
    }
    setConfirmationModalVisible(payload);

  };

  const onFinishFailed = (...args) => {
    console.log(args);
  }

  function submitMutation(payload) {
    setMutationLoading(true);
    const gates = flagFeatures.map(feature => ({flagFeatureId: feature.id, value: 'false'}));
    const newAccountPayload = {
      "organization_id": payload.organization.id,
      "type": "LegalAccount",
      "name": payload.name,
      "document_number": cnpj.generate(false),
      "legal_name": payload.name,
      "sessionTimeout": 86400000,
      "flagFeatureGates": { data: gates },
      "contracts": {
        "data": {
          "identifier": "CTRL0001",
          "processing_quota": payload.contract.processing_quota,
          "storage_quota": 0,
          "seats_quota": payload.contract.seats_quota,
          "period_in_months": payload.contract.period_in_months,
          "exceeded_image_value": 0,
          "exceeded_seat_value": 0,
          "exceeded_storage_value": 0,
          "started_at": payload.contract.started_at.toISOString(),
          "imageUsageEntries": {
            "data": Array.from(Array(payload.contract.period_in_months)).map((nothing, index) => {
              return {
                "entryDate": moment(payload.contract.started_at).add(index, 'months').format('YYYY-MM-DD'),
                "updatedAt": "now()",
                "createdAt": "now()",
                "credit": payload.contract.processing_quota,
                "debit": 0,
                "description": "creditDescription"
              };
            })
          }
        }
      },
      "accountStatuses": {
        "data": {
          "is_active": true,
          "reason": "Contract Signed",
        }
      }
    }
    createNewAccount({variables: {object: newAccountPayload}})
      .then(({ data }) => {
        openNotification(data);
        setTimeout(() => {
          setMutationLoading(false);
          setConfirmationModalVisible(false);
          setNewAccountModalVisible(false);
          resetStates();
        }, 1500);
      })
      .catch((error) => {
        console.warn("there was an error sending the query", error);
        resetStates();
      });
  }

  return (
    <div>
      <Drawer
        title="Criar nova Conta"
        placement="right"
        visible={newAccountModalVisible}
        onClose={() => setNewAccountModalVisible(false)}
        width={700}
      >
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            name: null,
            organization_id: null,
            contract__started_at: moment(),
            contract__period_in_months: 1,
            contract__processing_quota: 1000,
            contract__seats_quota: 20
          }}>
          <Title level={5}>Informações Gerais</Title>
          <Form.Item
            rules={[{ required: true }]}
            label="Nome da Conta (Obra)"
            name="name">
            <Input placeholder="Nome da Conta (Obra)" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Organização"
            name="organization">
            <OrganizationsSelector />
          </Form.Item>

          <Title level={5}>Contrato</Title>
          <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline" size={30}>
            <Form.Item
              rules={[{ required: true }]}
              label="Data de Inicio"
              name="contract__started_at">
                <DatePicker
                  style={{minWidth: '140px'}}
                  showToday={true}
                  format={"DD/MM/YYYY"}
                />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              label="Duração (meses)"
              name="contract__period_in_months">
              <InputNumber
                style={{ minWidth: '140px' }}
                min={1}
                max={99}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              label="Qtde Imagens"
              name="contract__processing_quota">
              <InputNumber
                style={{ minWidth: '140px' }}
                min={100}
                max={99999}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              label="Qtde Assentos"
              name="contract__seats_quota">
              <InputNumber
                style={{ minWidth: '140px' }}
                min={1}
                max={50}
              />
            </Form.Item>

          </Space>
          <Divider />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Adicionar Novo projeto
            </Button>
          </Form.Item>
        </Form>

      </Drawer>
      <Modal
        title="Confirmação Criação Conta"
        visible={!!confirmationModalVisible}
        width={600}
        footer={[
          <Button key="back" onClick={() => setConfirmationModalVisible(null)}>
            Retornar
          </Button>,
          <Button
            key="create_account"
            loading={mutationLoading}
            type={"primary"}
            onClick={() => submitMutation(confirmationModalVisible)}
          >
            Criar Conta
          </Button>,
        ]}
      >
        <div>
          <Descriptions title="Informações Contrato" bordered size="small">
            <Descriptions.Item label="Nome Projeto" span={3}>
              {confirmationModalVisible?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Empresa" span={3}>
              <div>
                <div>{confirmationModalVisible?.organization?.label}</div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Vigência Contrato" span={3}>
              {moment(confirmationModalVisible?.contract?.started_at).format("DD/MM/YYYY")} até {moment(confirmationModalVisible?.contract?.started_at).add(confirmationModalVisible?.contract?.period_in_months, 'months').format("DD/MM/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Período" span={3}>
              {moment.duration(confirmationModalVisible?.contract?.period_in_months, 'months').humanize()}
            </Descriptions.Item>
            <Descriptions.Item label="Imagens por período" span={3}>
              {confirmationModalVisible?.contract?.processing_quota} Imagens
            </Descriptions.Item>
            <Descriptions.Item label="Número de Assentos" span={3}>
              {confirmationModalVisible?.contract?.seats_quota} Assento(s)
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
    </div>
  );
};

NewAccount.propTypes = {
  newAccountModalVisible: PropTypes.bool,
  setNewAccountModalVisible: PropTypes.func
}

export default NewAccount;
